<template>
  <div
    class="relative"
    v-if="blog"
  >
    <InnerHeader />
    <div class="flex flex-col lg:flex-row ais jcb main-container pt-6 lg:border-t border-gray-100 relative px-10 md:px-0 md:mt-8 lg:mt-14">
      <h1 class="min-w-max text-base lg:text-xl xl:text-2xl merienda-bold uppercase order-2 md:order-1 mt-2 md:mt-0 text-brown-light">{{$t('Blog')}}</h1>
      <ol class="min-w-max flex aic space-x-3 text-xs order-1 md:order-2">
        <li><router-link to="/" class="text-gray-500">{{$t('Home')}}</router-link></li>
        <li class="text-gray-400"><i class="fa fa-angle-right"></i></li>
        <li><router-link to="/blogs" class="text-gray-500">{{$t('Blogs')}}</router-link></li>
        <li class="text-gray-400"><i class="fa fa-angle-right"></i></li>
        <li class="nunitoSans-bold">{{blog.title.substr(0, 15) + '..'}}</li>
      </ol>
    </div>
    <div class="main-container mt-12 md:mt-16 ais px-8 md:px-0">
      <div class="overflow-hidden">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <div>
              <div class="flex w-20p aic jcb text-sm ">
                <div class="text-gray-900 relative inline-block mr-2">
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  >
                    <path d="M0 12v1h23v-1h-23z" />
                  </svg>
                </div>
                <span class="whitespace-nowrap dmSans-medium fs-11 categories-in">{{blog.created_at.split('T')[0]}}</span>
              </div>
              <h3 class="mt-2 text-xl sm:text-2xl nunitoSans-bold">{{blog.title}}</h3>
            </div>
          </div>
          <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="relative lg:row-start-1 lg:col-start-2">
              <svg
                class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                <div>
                  <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      class="shadow-2xl object-cover object-center h-full"
                      :src="blog.image?storageUrl+blog.image:'/img/about2.jpg'"
                      :alt="blog.title"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="ql-editor mt-12 lg:mt-0"
              v-html="blog.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
  </div>
</template>
<script>
import InnerHeader from "../components/InnerHeader.vue";
import MainFooter from "../components/MainFooter.vue";
export default {
  components: { InnerHeader, MainFooter },
  //   data() {
  //         return {
  //         showAboutModal: false,
  //         showContactModal: false,
  //         };
  //     },
  computed: {
    blogId() {
      return Number(this.$route.params.id);
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    blog() {
      return this.$store.state.orderiomApiPackage.restaurant.blog;
    },
  },
  methods: {
    formatDate(date) {
      date = new Date(date);
      var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var day = date.getUTCDate();
      var monthIndex = date.getUTCMonth();
      var year = date.getFullYear();
      return day + " " + monthNames[monthIndex] + " " + year;
    },
  },
  created() {
    this.$store.dispatch("restaurant/getBlog", {
      blogId: this.blogId,
    });
  },
};
</script>

<style scoped>
  @import url("https://cdn.quilljs.com/1.3.6/quill.snow.css");
</style>